import {
  ErrorBoundary,
  OverviewPage as OverviewPageComponent,
} from '@elseu/sdu-titan-product-site-components';

const OverviewPage = () => {
  return (
    <ErrorBoundary tags={[{ key: 'component', value: 'OverviewPage' }]}>
      <OverviewPageComponent />
    </ErrorBoundary>
  );
};

export default OverviewPage;
